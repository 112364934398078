import React from 'react';
import { Flex } from 'rebass';

import SeasonList from './SeasonList';

const ProductFormSeason = ({ id, record }: any) => {
  return (
    <Flex width={1}>
      <SeasonList productId={id} {...record} />
    </Flex>
  );
};

export default ProductFormSeason;
