import * as React from 'react';
import { useQuery, Loading } from 'react-admin';
import { Box, Flex, Text } from 'rebass';

import SeasonCreate from './SeasonCreate';
import SeasonDelete from './SeasonDelete';

const months = [
  { value: 'JANUARY', description: 'Janvier' },
  { value: 'FEBRUARY', description: 'Février' },
  { value: 'MARCH', description: 'Mars' },
  { value: 'APRIL', description: 'Avril' },
  { value: 'MAY', description: 'Mai' },
  { value: 'JUNE', description: 'Juin' },
  { value: 'JULY', description: 'Juillet' },
  { value: 'AUGUST', description: 'Août' },
  { value: 'SEPTEMBER', description: 'Septembre' },
  { value: 'OCTOBER', description: 'Octobre' },
  { value: 'NOVEMBER', description: 'Novembre' },
  { value: 'DECEMBER', description: 'Décembre' },
];

const getSelectedMonth = (data) =>
  data.reduce((prev, curr) => {
    return [...prev, curr.season_month];
  }, []);

const SeasonList = ({ recipeId }: any) => {
  const { data, loading, error }: any = useQuery({
    type: 'getList',
    resource: 'recipe_season_month',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'recipe_id', order: 'ASC' },
      filter: { recipe_id: recipeId },
    },
  });

  if (loading) return <Loading />;
  if (error) return <></>;
  if (!data) return null;

  const selectedMonth = getSelectedMonth(data);
  return (
    <Box width={1}>
      <Flex>
        <Box p={2} width={1}>
          <Flex
            flexWrap="wrap"
            width="100%"
            sx={{
              border: '1px solid black',
            }}
          >
            {months.map(({ value, description }) =>
              selectedMonth.includes(value) ? (
                <SeasonDelete
                  id={
                    data.filter(({ season_month }) => season_month === value)[0]
                      .id
                  }
                >
                  <Text>{description}</Text>
                </SeasonDelete>
              ) : (
                <SeasonCreate recipeId={recipeId} seasonMonth={value}>
                  <Text>{description}</Text>
                </SeasonCreate>
              )
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default SeasonList;
