import React, { useState } from 'react';
import {
  Button,
  useUpdate,
  useNotify,
  useRefresh,
  FormWithRedirect,
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import RecipeStepProductForm from './RecipeStepProductForm';

const RecipeStepProductQuickEditButton = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate('recipe_step_product', data.id);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { payload: { data: { ...values, id: data.id } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update product"
      >
        <DialogTitle>Ajouter un ingrédient à l'étape {data.name}</DialogTitle>
        <FormWithRedirect
          resource="recipe_step"
          save={handleSubmit}
          render={form => (
            <RecipeStepProductForm
              {...form}
              loading={loading}
              handleCloseClick={handleCloseClick}
              data={data}
            />
          )}
        />
      </Dialog>
    </>
  );
};

export default RecipeStepProductQuickEditButton;
