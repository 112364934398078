import React from 'react';

import { Box } from '@material-ui/core';
import RecipeStepList from './RecipeStepList';

const RecipeFormProduct = ({ id, record }: any) => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <RecipeStepList recipeid={id} />
    </Box>
  );
};

export default RecipeFormProduct;
