import React, { useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import { useDebouncedCallback } from 'use-debounce';
import { Box, Flex } from 'rebass';
import {
  Create,
  CreateProps,
  SimpleForm,
  Toolbar,
  TopToolbar,
  ListButton,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import ProductForm from './ProductForm';

const MyAutoComplete = ({ options, search, selected }) => (
  <Autocomplete
    options={options}
    getOptionLabel={(option: any) => option.name}
    style={{ width: '100%', height: '80px' }}
    onChange={(event, newValue) => {
      selected(newValue);
    }}
    onInputChange={(event, newInputValue) => {
      search(newInputValue);
    }}
    renderInput={(params) => <TextField {...params} label="API des produits" />}
  />
);

const ProductCreateActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const ProductCreateToolbar = (props: any) => (
  <Toolbar
    record={props.record}
    basePath={props.basePath}
    undoable={true}
    invalid={props.invalid}
    handleSubmit={props.handleSubmit}
    saving={props.saving}
    resource="product"
  />
);

const ProductCreate = ({ classes, ...props }: CreateProps) => {
  const [choices, setChoices] = useState([]);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState({});
  const debouncedCallback = useDebouncedCallback((value: any) => {
    setSearch(value);
  }, 1000);

  useEffect(() => {
    if (search.length > 0) {
      fetch('https://hasura.lesfoodcuisine.fr/v1/graphql', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-hasura-admin-secret': 'WjqRluDWPsspRZp9PytYXiLQqyk3dV8g',
        },
        body: JSON.stringify({
          query: `{ aliments(nom:"${search}", first:10) { alimCode alimNomFr composition { constCode teneur constNomFr } } }`,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          setChoices(
            res.data.aliments.map((data) => ({
              id: data.alimCode,
              name: data.alimNomFr,
              composition: data.composition,
            }))
          )
        );
    }
  }, [search]);

  return (
    <Create {...props} actions={<ProductCreateActions />} title="Ingrédient">
      <SimpleForm toolbar={<ProductCreateToolbar />}>
        <Flex p={2} width={1}>
          <Box p={2} width={8 / 12}>
            <MyAutoComplete
              options={choices}
              search={debouncedCallback}
              selected={setSelected}
            />
          </Box>
          <Box p={2} width={4 / 12}>
            <ReferenceInput
              label="Base de produits Food'Cuisine"
              source="product"
              reference="product"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={1000}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>
          </Box>
        </Flex>
        <ProductForm
          selected={selected}
          choices={choices}
          debouncedCallback={debouncedCallback}
          setSelected={setSelected}
        />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
