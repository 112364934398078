import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useDelete,
  useNotify,
  useRefresh,
  FormWithRedirect,
} from 'react-admin';
import IconContentDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const RecipeStepQuickDeleteButton = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [deleteOne, { loading }] = useDelete('recipe_step', data.id);
  const notify = useNotify();
  const refresh = useRefresh();

  // const handleClick = () => {
  //   setShowDialog(true);
  // };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    deleteOne(
      {},
      {
        onSuccess: ({ data }) => {
          fetch('https://hasura.lesfoodcuisine.fr/v1/graphql', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-hasura-admin-secret': 'WjqRluDWPsspRZp9PytYXiLQqyk3dV8g',
            },
            body: JSON.stringify({
              query: `mutation {delete_recipe_step_product(where: {recipe_step_id: {_eq: "${data.id}"}}){
                affected_rows
              }}`,
            }),
          })
            .then((res) => res.json())
            .then((res) => ({}));

          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  const { name } = data;

  return (
    <>
      <Button onClick={handleSubmit} label="Supprimer">
        <IconContentDelete />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Modifier l'étape : {name}</DialogTitle>
        <FormWithRedirect
          resource="recipe_step"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  label="Nom de l'étape"
                  source="name"
                  validate={required()}
                  initialValue={name}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default RecipeStepQuickDeleteButton;
