import React, { cloneElement } from 'react';
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  TextInput,
  DateField,
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';

const SubscriptionCodeFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
    <TextInput source="code" />
  </Filter>
);

const SubscriptionCodeListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton label="Ajouter" basePath={basePath} />
      <ExportButton
        label="Exporter"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

const TimeField = ({ record = {} }: any) => <span>{record.time} mois</span>;

const SubscriptionCodeList = (props) => (
  <List
    title="Codes d'abonnement"
    {...props}
    filters={<SubscriptionCodeFilter />}
    actions={<SubscriptionCodeListActions />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <TextField label="Code" source="code" />
      <TextField label="Label" source="label" />
      <DateField label="Début" source="started_at" />
      <DateField label="Fin" source="ended_at" />
      <TimeField label="Durée" source="time" />
      <TextField label="Nombre d'utilisateur" source="user_count" />
    </Datagrid>
  </List>
);

export default SubscriptionCodeList;
