import ChefIcon from '@material-ui/icons/AssignmentInd';

import ChefList from './ChefList';

const resource = {
  list: ChefList,
  icon: ChefIcon,
};

export default resource;
