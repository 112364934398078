import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  ReferenceField,
  BooleanField,
  DateField,
} from 'react-admin';

import { recipe_type_enum } from '../enums';

const RecipeFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
  </Filter>
);

const RecipeTypeField = ({ record = {} }: any) => (
  <span>
    {recipe_type_enum.filter((type) => type.id === record.type)[0].name}
  </span>
);

const RecipeList = (props) => (
  <List
    title="Les recettes"
    {...props}
    filters={<RecipeFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <RecipeTypeField source="type" />
      <ReferenceField label="Catégorie" source="category" reference="category">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField label="En ligne" source="is_publish" />
      <DateField label="Créé le" source="created_at" />
      <DateField label="Mis à jour le" source="updated_at" />
    </Datagrid>
  </List>
);

export default RecipeList;
