import gql from 'graphql-tag';

export const GET_LIST_INVOICE = gql`
  fragment userInvoiceList on user_invoice {
    user_subscription_id
    id
    created_at
    amount
    user_subscription {
      type
      user {
        id
        is_active
        created_at
        email
        password
        firstname
        lastname
        avatar
        avatar_preview
        avatar_color
        gender
        username
        description
        phone
        address
        zip
        city
        birth
        user_favoris_aggregate {
          aggregate {
            count
          }
        }
        user_subscription {
          id
          ended_at
          status
        }
        user_subscription_code {
          id
          started_at
          ended_at
          subscription_code {
            id
            label
            time
          }
        }
      }
    }
  }
`;
