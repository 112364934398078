import React from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Invoice } from '@lesfoodcuisine/shared';
import { format, addMonths } from 'date-fns';
import { fr } from 'date-fns/locale';

const MyDoc = ({ record }) => {
  return (
    <Invoice
      invoice={{
        id: record.id,
        invoice_no: `IFC${format(
          new Date(record.created_at),
          'yyyy'
        )}${record.id.toString().padStart(9, '0')}`,
        company: 'La Table Et Fêtes',
        companyCapital: 'S.A.R.L au capital de 7500€',
        companySiren: 'R.C.S NANTERRE B 484729967',
        tvaIntra: 'FR574484729967',
        companySiret: '484729967000146/APE:5621Z',
        email: 'abonnement@lesfoodcuisine.fr',
        phone: '01 30 90 61 44',
        address: '1 place Paul Verlaine 92100 Boulogne Billancourt',
        trans_date: format(new Date(record.created_at), 'd MMMM yyyy', {
          locale: fr,
        }),
        paymentType: 'Carte bancaire',
        subscription:
          record.user_subscription.type === 'MONTHLY' ? 'mensuel' : 'annuel',
        subscriptionType:
          record.user_subscription.type === 'MONTHLY'
            ? 'Abonnement résiliable chaque mois'
            : 'Abonnement résiliable chaque année',
        client: `${record.user_subscription.user.firstname} ${record.user_subscription.user.lastname}`,
        clientMail: record.user_subscription.user.email,
        nextBill: format(
          addMonths(
            new Date(record.created_at),
            record.user_subscription.type === 'MONTHLY' ? 1 : 12
          ),
          'd MMMM yyyy',
          {
            locale: fr,
          }
        ),
        items: [
          {
            sno: 1,
            desc:
              record.user_subscription.type === 'MONTHLY'
                ? 'S-1 Abonnement mensuel'
                : 'S-2 Abonnement annuel',
            descDetails: 'Abonnement résiliable',
            qty: 1,
            tva: 20,
            priceUnit: record.user_subscription.type === 'MONTHLY' ? 5 : 50,
            priceHT: record.user_subscription.type === 'MONTHLY' ? 5 : 50,
            priceTTC: record.user_subscription.type === 'MONTHLY' ? 6 : 60,
          },
        ],
      }}
    />
  );
};

export const UserInvoiceDownload = (props: any) => {
  const [instance] = usePDF({ document: <MyDoc record={props.record} /> });

  return (
    <a
      href={instance.url}
      download={`IFC${format(
        new Date(props.record.created_at),
        'yyyy'
      )}${props.record.id.toString().padStart(9, '0')}`}
      onClick={(e) => e.stopPropagation()}
      style={{ textDecoration: 'none' }}
    >
      Télécharger
    </a>
  );
};
