import React from 'react';
import { FieldProps } from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { UserListFragment } from '../generated/graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    zIndex: 999,
  },
}));

const SubscriptionField = (props: FieldProps<UserListFragment>) => {
  const classes = useStyles();
  const { record } = props;
  return record ? (
    <div className={classes.root}>
      {record.user_subscription ? (
        <Link
          className={classes.link}
          to={`/user_subscription/${props.record?.user_subscription.id}`}
          onClick={(e) => e.stopPropagation()}
        >
          Abonnement
        </Link>
      ) : null}
      {record.user_subscription_code ? (
        <Link
          className={classes.link}
          to={`/subscription_code/${props.record?.user_subscription_code.subscription_code.id}`}
          onClick={(e) => e.stopPropagation()}
        >
          Code
        </Link>
      ) : null}
    </div>
  ) : null;
};

export default SubscriptionField;
