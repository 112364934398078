import React from 'react';
import {
  Create,
  CreateProps,
  TopToolbar,
  ListButton,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  TabbedForm,
  FormTab,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import RecipeForm from './RecipeForm';

const RecipeTitle = ({ record }: any) => (
  <span>Création d'une nouvelle recette</span>
);

const styles = {
  comment: {
    maxWidth: '30em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const RecipeCreateActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const RecipeEditToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton />
  </Toolbar>
));

const RecipeCreate = ({ classes, ...props }: CreateProps) => {
  return (
    <Create
      {...props}
      actions={<RecipeCreateActions />}
      title={<RecipeTitle />}
    >
      <TabbedForm toolbar={<RecipeEditToolbar />}>
        <FormTab label="Recette">
          <RecipeForm />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default RecipeCreate;
