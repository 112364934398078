import React from 'react';
import {
  AutocompleteInput,
  TextInput,
  DateInput,
  NumberInput,
  Toolbar,
  FormWithRedirect,
  Button,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const SubscriptionCodeForm = (props) => {
  const { isEdit } = props;
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    Informations
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        fullWidth
                        label="Code"
                        source="code"
                        validation={{ required: true }}
                        disabled={isEdit}
                      />
                      {!isEdit && (
                        <Button
                          onClick={(event) => {
                            formProps.form.change(
                              'code',
                              Math.random()
                                .toString(36)
                                .substring(4)
                                .toUpperCase()
                            );
                          }}
                          label="Générer un code"
                        />
                      )}
                    </Box>
                    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput fullWidth label="Label" source="label" />
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Période
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateInput fullWidth source="started_at" label="Début" />
                    </Box>
                    <Box flex={1}>
                      <DateInput fullWidth source="ended_at" label="Fin" />
                    </Box>
                  </Box>

                  <Box mt="1em" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: '1em' }}
                  mt={{ xs: '1em', lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Nombre d‘abonnement
                  </Typography>
                  <div>
                    <NumberInput fullWidth source="user_count" label="" />
                  </div>
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Durée de l'offre d'abonnement
                  </Typography>
                  <div>
                    <AutocompleteInput
                      fullWidth
                      source="time"
                      label="Nombre de mois"
                      choices={Array.from({ length: 120 }, (e, i) => {
                        const month = i + 1;
                        return { id: month, name: `${month} mois` };
                      })}
                    />
                  </div>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="user"
            />
          </form>
        </Card>
      )}
    />
  );
};

export default SubscriptionCodeForm;
