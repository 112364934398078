import React, { useCallback, cloneElement } from 'react';
import {
  Filter,
  List,
  SearchInput,
  TextInput,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
} from 'react-admin';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Drawer, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ChefListMobile from './ChefListMobile';
import ChefListDesktop from './ChefListDesktop';
import ChefEdit from './ChefEdit';
import ChefCreate from './ChefCreate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const ChefFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
    <TextInput source="email" />
  </Filter>
);

const ChefListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton label="Ajouter" basePath={basePath} />
      <ExportButton
        label="Exporter"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

const ChefList = (props: any) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/chef');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/chef/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(match && match.params);

          return (
            <>
              <List
                {...props}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                actions={<ChefListActions />}
                filters={<ChefFilter />}
                perPage={25}
                sort={{ field: 'created_at', order: 'DESC' }}
              >
                {isXSmall ? (
                  <ChefListMobile />
                ) : (
                  <ChefListDesktop
                    selectedRow={
                      isMatch
                        ? parseInt((match as any).params.id, 10)
                        : undefined
                    }
                  />
                )}
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch && match?.params.id !== 'create' ? (
                  <ChefEdit
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
                {isMatch && match?.params.id === 'create' ? (
                  <ChefCreate onCancel={handleClose} {...props} />
                ) : null}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  );
};

export default ChefList;
