import React, { useState } from 'react';
import {
  Button,
  useDelete,
  useNotify,
  useRefresh,
  FormWithRedirect,
} from 'react-admin';
import IconContentDelete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import RecipeStepProductForm from './RecipeStepProductForm';

const RecipeStepProductQuickDeleteButton = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [deleteOne, { loading }] = useDelete('recipe_step_product', data.id);
  const notify = useNotify();
  const refresh = useRefresh();

  // const handleClick = () => {
  //   setShowDialog(true);
  // };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    deleteOne(
      {},
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleSubmit} label="">
        <IconContentDelete />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update product"
      >
        <DialogTitle>Ajouter un ingrédient à l'étape {data.name}</DialogTitle>
        <FormWithRedirect
          resource="recipe_step"
          save={handleSubmit}
          render={form => (
            <RecipeStepProductForm
              {...form}
              loading={loading}
              handleCloseClick={handleCloseClick}
              data={data}
            />
          )}
        />
      </Dialog>
    </>
  );
};

export default RecipeStepProductQuickDeleteButton;
