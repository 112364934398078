import React, { useState, useEffect } from 'react';
import useStateWithCallback from 'use-state-with-callback';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  SelectInput,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Flex, Text } from 'rebass';

import ProductAutocomplete from '../product/Autocomplete';
import { product_quantity_type_enum } from '../enums';

const RecipeStepProductForm = ({
  handleSubmitWithRedirect,
  saving,
  form,
  loading,
  handleCloseClick,
  data,
}: any) => {
  const [productName, setProductName] = useState('');
  const [selectedProduct, setSelectedProduct] = useStateWithCallback(
    null,
    (selectedProduct) => {
      handleChange(form.getState().values);
    }
  );

  useEffect(() => {
    if (data && data.product_id) {
      fetch('https://hasura.lesfoodcuisine.fr/v1/graphql', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-hasura-admin-secret': 'WjqRluDWPsspRZp9PytYXiLQqyk3dV8g',
        },
        body: JSON.stringify({
          query: `{
            product(where: {id: {_eq: "${data.product_id}"}}) {
              id
              name
              name_plural
            }
          }`,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          setSelectedProduct(
            res.data.product.map((data) => ({
              id: data.id,
              name: data.name,
              name_plural: data.name_plural,
            }))[0]
          )
        );
    }
  }, [data, setSelectedProduct]);

  const pluralize = (name, plural, count) => {
    return count > 1 ? plural : name;
  };

  const prefixProductName = (name, type, quantity) => {
    if (type === 'UNITE') {
      return pluralize(
        name,
        selectedProduct.name_plural
          ? selectedProduct.name_plural.toLowerCase()
          : name,
        quantity
      );
    }
    var vowelRegex = '^[aáàâäãåeéèêëiíìîïoóòôöõuúùûüœh].*';
    var matched = name.match(vowelRegex);
    return matched ? `d'${name}` : `de ${name}`;
  };

  const handleProductSelected = (data) => {
    setSelectedProduct(data);
    form.change('product_id', data.id);
  };

  const handleChange = (values) => {
    const { quantity, quantity_type, description } = values;

    const name = `${quantity ? quantity : ''} ${
      quantity_type
        ? product_quantity_type_enum.filter(
            (quantity) => quantity.id === quantity_type
          )[0]?.contracted
        : ''
    } ${
      selectedProduct
        ? prefixProductName(
            selectedProduct.name.toLowerCase(),
            quantity_type,
            quantity
          )
        : ''
    } ${description ? description : ''}`;

    setProductName(name);
    form.change('label', name);
  };

  return (
    <>
      <DialogContent>
        <Flex>
          <Box m={2} width={1} sx={{ height: '50px' }}>
            <Text fontSize={3}>{productName}</Text>
          </Box>
        </Flex>
        <Flex>
          <Box width={1 / 2}>
            <TextInput
              label="Quantité"
              source="quantity"
              validate={required()}
              fullWidth
              onChange={() => handleChange(form.getState().values)}
              initialValue={data && data.quantity}
            />
          </Box>
          <Box width={1 / 2}>
            <SelectInput
              fullWidth
              label="Quantité type"
              source="quantity_type"
              choices={product_quantity_type_enum}
              onChange={() => handleChange(form.getState().values)}
              initialValue={data && data.quantity_type}
            />
          </Box>
        </Flex>
        <ProductAutocomplete onSelect={handleProductSelected} />
        <TextInput
          label="Détail"
          source="description"
          fullWidth
          onChange={() => handleChange(form.getState().values)}
          initialValue={data && data.description}
        />
      </DialogContent>
      <DialogActions>
        <Button
          label="ra.action.cancel"
          onClick={handleCloseClick}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={handleSubmitWithRedirect}
          saving={saving}
          disabled={loading}
        />
      </DialogActions>
    </>
  );
};

export default RecipeStepProductForm;
