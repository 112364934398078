import React from 'react';
import { useForm } from 'react-final-form';
import {
  FormWithRedirect,
  ReferenceManyField,
  TextField,
  useCreate,
  useNotify,
  useRefresh,
  SingleFieldList,
  TextInput,
  SaveButton,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chatBox: {
    overflowY: 'scroll',
    height: '500px',
  },
  answerBox: {
    backgroundColor: '#A3D773',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',
    padding: '16px',
    margin: '10px 40px 10px 0px',
  },
  answerItem: {
    fontSize: '14px',
  },
  replyList: { flexDirection: 'column' },
  replyItem: {
    backgroundColor: '#e0f3fa',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    padding: '16px',
    margin: '10px 10px 10px 20px',
    fontSize: '14px',
  },
}));

const TicketForm = (props: any) => {
  const [create, { loading }] = useCreate('ticket_reply');
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const form = useForm();

  const handleSubmit = async (values) => {
    const {
      record: { id },
    } = props;
    create(
      {
        payload: {
          data: {
            ...values,
            ticket_id: id,
            owner: 'CHEF',
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change('ticket_id', data.ticket_id);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Card>
        <CardContent className={classes.chatBox}>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box
                  flex={1}
                  mr={{ xs: 0, sm: '0.5em' }}
                  className={classes.answerBox}
                >
                  <TextField
                    className={classes.answerItem}
                    label="Message"
                    source="message"
                    // {...props}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} ml={{ md: 0, lg: '1em' }}>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceManyField
                    label="Réponses"
                    target="ticket_id"
                    reference="ticket_reply"
                    sortBy="ticket_reply.created_at"
                    {...props}
                  >
                    <SingleFieldList
                      className={classes.replyList}
                      linkType={null}
                    >
                      <TextField
                        className={classes.replyItem}
                        source="message"
                      />
                    </SingleFieldList>
                  </ReferenceManyField>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box display={{ md: 'block', lg: 'flex' }}>
        <Box flex={2} ml={{ md: 0, lg: '1em' }}>
          <FormWithRedirect
            resource="ticket_reply"
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <TextInput multiline source="message" fullWidth />
                <SaveButton
                  label="Envoyer"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default TicketForm;
