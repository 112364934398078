import React from 'react';
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  Toolbar,
  TopToolbar,
  ListButton,
} from 'react-admin';

import ProductForm from './ProductForm';
import ProductFormSeason from './ProductFormSeason';

const ProductTitle = ({ record }: any) => (
  <span>
    Product #{record.id} - {record.first_name} {record.last_name}
  </span>
);

const ProductEditActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const ProductEditToolbar = (props: any) => {
  return (
    <Toolbar
      record={props.record}
      basePath={props.basePath}
      undoable={true}
      invalid={props.invalid}
      handleSubmit={props.handleSubmit}
      saving={props.saving}
      resource="product"
    />
  );
};

const ProductEdit = ({ classes, ...props }: EditProps) => {
  return (
    <Edit {...props} actions={<ProductEditActions />} title={<ProductTitle />}>
      <TabbedForm toolbar={<ProductEditToolbar />}>
        <FormTab label="Information">
          <ProductForm />
        </FormTab>
        <FormTab label="Saisons">
          <ProductFormSeason {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ProductEdit;
