import UserSubscriptionIcon from '@material-ui/icons/CardMembership';

import UserSubscriptionList from './UserSubscriptionList';
import UserSubscriptionEdit from './UserSubscriptionEdit';

const resource = {
  list: UserSubscriptionList,
  edit: UserSubscriptionEdit,
  icon: UserSubscriptionIcon,
};

export default resource;
