import SubscriptionCodeIcon from '@material-ui/icons/PlaylistAddCheck';

import SubscriptionCodeCreate from './SubscriptionCodeCreate';
import SubscriptionCodeEdit from './SubscriptionCodeEdit';
import SubscriptionCodeList from './SubscriptionCodeList';

const resource = {
  list: SubscriptionCodeList,
  create: SubscriptionCodeCreate,
  edit: SubscriptionCodeEdit,
  icon: SubscriptionCodeIcon,
};

export default resource;
