import { buildFields } from 'ra-data-hasura';
import { GET_LIST, GET_ONE } from 'react-admin';
import { ResourceType, FetchType } from 'ra-data-hasura';

import { GET_LIST_USERS } from './queries/users';
import { GET_LIST_SUBSCRIPTION } from './queries/subscription';
import { GET_LIST_INVOICE } from './queries/invoice';
import { GET_LIST_TICKET } from './queries/ticket';
import { GET_LIST_SUBSCRIPTION_CODE } from './queries/subscription_code';
import { GET_LIST_RECIPE, GET_ONE_RECIPE } from './queries/recipe';
import { GET_LIST_RECIPE_STEP } from './queries/recipe_step';
import { GET_LIST_RECIPE_STEP_PRODUCT } from './queries/recipe_step_product';
import { GET_LIST_PRODUCT, GET_ONE_PRODUCT } from './queries/product';
import { GET_LIST_PRODUCT_SEASON_MONTH } from './queries/product_season_month';
import { GET_LIST_RECIPE_SEASON_MONTH } from './queries/recipe_season_month';

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) =>
  queryAst.definitions[0].selectionSet.selections;

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES: any = {
  user: {
    [GET_LIST]: GET_LIST_USERS,
    [GET_ONE]: GET_LIST_USERS,
  },
  user_subscription: {
    [GET_LIST]: GET_LIST_SUBSCRIPTION,
    [GET_ONE]: GET_LIST_SUBSCRIPTION,
  },
  user_invoice: {
    [GET_LIST]: GET_LIST_INVOICE,
    [GET_ONE]: GET_LIST_INVOICE,
  },
  subscription_code: {
    [GET_LIST]: GET_LIST_SUBSCRIPTION_CODE,
    [GET_ONE]: GET_LIST_SUBSCRIPTION_CODE,
  },
  ticket: {
    [GET_LIST]: GET_LIST_TICKET,
    [GET_ONE]: GET_LIST_TICKET,
  },
  recipe: {
    [GET_LIST]: GET_LIST_RECIPE,
    [GET_ONE]: GET_ONE_RECIPE,
  },
  recipe_step: {
    [GET_LIST]: GET_LIST_RECIPE_STEP,
  },
  recipe_step_product: {
    [GET_LIST]: GET_LIST_RECIPE_STEP_PRODUCT,
  },
  product: {
    [GET_LIST]: GET_LIST_PRODUCT,
    [GET_ONE]: GET_ONE_PRODUCT,
  },
  product_season_months: {
    [GET_LIST]: GET_LIST_PRODUCT_SEASON_MONTH,
  },
  recipe_season_months: {
    [GET_LIST]: GET_LIST_RECIPE_SEASON_MONTH,
  },
};

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields = (type: ResourceType, fetchType: FetchType) => {
  const resourceName: string = type.name;

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType);
};

export default customBuildFields;
