import gql from 'graphql-tag';

export const GET_LIST_SUBSCRIPTION = gql`
  fragment userSubscriptionList on user_subscription {
    id
    type
    created_at
    started_at
    ended_at
    status
    reference
    pan
    expiry_month
    expiry_year
    payment_method_token
    next_billing
    user_id
    user_invoices {
      id
      amount
      created_at
    }
    user {
      id
      is_active
      created_at
      email
      password
      firstname
      lastname
      avatar
      avatar_preview
      avatar_color
      gender
      username
      description
      phone
      address
      zip
      city
      birth
      user_favoris_aggregate {
        aggregate {
          count
        }
      }
      user_subscription {
        id
        ended_at
        status
      }
      user_subscription_code {
        id
        started_at
        ended_at
        subscription_code {
          id
          label
          time
        }
      }
    }
  }
`;
