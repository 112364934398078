import React, { useState, useEffect } from 'react';
import buildHasuraProvider from 'ra-data-hasura';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import customBuildFields from '../custom-build-fields';
import App from './App';
import Error from './Error';
import Login from './Login';
import Loading from './Loading';

const uri = process.env.REACT_APP_HASURA_URL;

function AppWithAuth(props: any) {
  const [dataProvider, setDataProvider] = useState(null);
  const { isLoading, error, getIdTokenClaims, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isAuthenticated && !isLoading && !error) {
      void (async () => {
        const token = await getIdTokenClaims();
        const client = new ApolloClient({
          uri,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
            Authorization: `Bearer ${token.__raw}`,
          },
          cache: new InMemoryCache(),
        });
        const dp = await buildHasuraProvider({ client } as any, {
          buildFields: customBuildFields,
        });
        setDataProvider(() => dp as any);
      })();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]); // ts-ignore

  if (isLoading) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    return <Login />;
  }
  if (error) {
    return <Error errorMessage={'Something went wrong'} />;
  }

  return dataProvider ? <App dataProvider={dataProvider} /> : <Loading />;
}

export default AppWithAuth;
