import React from 'react';
import {
  TextInput,
  SelectInput,
  ReferenceInput,
  NumberInput,
  BooleanInput,
  DateTimeInput,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import {
  recipe_type_enum,
  recipe_num_type_enum,
  recipe_level_enum,
} from '../enums';

const RecipeForm = ({ isEdit = false }) => {
  return (
    <Card>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              Informations
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                  fullWidth
                  label="Nom de la recette"
                  source="name"
                  validation={{ required: true }}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput label="En ligne" source="is_publish" />
              </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                  fullWidth
                  label="Nombre"
                  source="number"
                  initialValue="4"
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <SelectInput
                  fullWidth
                  label="Nombre type"
                  source="number_type"
                  initialValue="PERSONNE"
                  choices={recipe_num_type_enum}
                />
              </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1 / 2} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                  fullWidth
                  label="Identifiant de la vidéo"
                  source="video"
                  validation={{ required: true }}
                />
              </Box>
              <Box flex={1 / 2} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                  fullWidth
                  label="Identifiant de l'évenement live"
                  source="video_live_event"
                  validation={{ required: true }}
                />
              </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <SelectInput
                  fullWidth
                  label="Type"
                  source="type"
                  choices={recipe_type_enum}
                  validation={{ required: true }}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput
                  fullWidth
                  label="Catégorie"
                  source="category"
                  reference="category"
                >
                  <SelectInput source="name" validation={{ required: true }} />
                </ReferenceInput>
              </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <DateTimeInput
                  fullWidth
                  label="Date du live"
                  source="live_date"
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <SelectInput
                  fullWidth
                  label="Niveau"
                  source="level"
                  choices={recipe_level_enum}
                />
              </Box>
            </Box>
            {isEdit && (
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1 / 2} mr={{ xs: 0, sm: '0.5em' }}>
                  <DateTimeInput
                    fullWidth
                    label="Date de création"
                    source="created_at"
                  />
                </Box>
                <Box flex={1 / 2} mr={{ xs: 0, sm: '0.5em' }} />
              </Box>
            )}
            <Box mt="1em" />
            <Typography variant="h6" gutterBottom>
              Préparation
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                  fullWidth
                  label="Temps de préparation (min)"
                  source="preparation_time"
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput
                  fullWidth
                  label="Temps total (min)"
                  source="total_time"
                />
              </Box>
            </Box>
            <Box mt="1em" />
            <Typography variant="h6" gutterBottom>
              Les Ustensiles
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput
                  fullWidth
                  label="Liste d'ustensiles"
                  source="utensil"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RecipeForm;
