import CategoryIcon from '@material-ui/icons/Category';

import CategoryList from './CategoryList';
// import CategoryEdit from './CategoryEdit';

const resource = {
  list: CategoryList,
  // edit: CategoryEdit,
  icon: CategoryIcon,
};

export default resource;
