export const recipe_type_enum = [
  { id: 'LIVE', name: 'Live' },
  { id: 'RECIPE', name: 'Recette' },
  { id: 'TECHNIQUE', name: 'Tour de mains' },
  { id: 'BASIC', name: 'Fondamentaux' },
];

export const recipe_num_type_enum = [
  { id: 'PERSONNE', name: 'Personne(s)' },
  { id: 'GRAMME', name: 'Gramme(s)' },
  { id: 'KILO', name: 'Kilo(s)' },
  { id: 'CENTILITRE', name: 'Centilitre(s)' },
  { id: 'LITRE', name: 'Litre(s)' },
  { id: 'BOUCHEE', name: 'Bouchée(s)' },
  { id: 'PIECE', name: 'Pièce(s)' },
  { id: 'PATE', name: 'Pâte(s)' },
  { id: 'POT', name: 'Pot(s)' },
];

export const recipe_level_enum = [
  { id: 'FACILE', name: 'Facile' },
  { id: 'MOYEN', name: 'Moyen' },
  { id: 'DIFFICILE', name: 'Difficile' },
];

export const product_type_enum = [
  { id: 'FRUITS', name: 'Fruits' },
  { id: 'LEGUMES', name: 'Légumes' },
  { id: 'POISSONS', name: 'Poissons' },
  { id: 'VIANDES', name: 'Viandes' },
  { id: 'BOF', name: 'Beurres, oeufs et fromages' },
  { id: 'EPICERIE', name: 'Épicerie' },
  { id: 'SURGELES', name: 'Surgelés' },
  { id: 'USTENSILES', name: 'Ustensiles' },
  { id: 'DIVERS', name: 'Divers' },
];

export const product_quantity_type_enum = [
  { id: 'UNITE', name: 'Unité', contracted: '' },
  { id: 'VALEUR_NULLE', name: 'Valeur nulle', contracted: '' },
  { id: 'GRAMME', name: 'Gramme', contracted: 'Gr' },
  { id: 'KILO', name: 'Kilogramme', contracted: 'Kgr' },
  {
    id: 'CENTILITRE',
    name: 'Centilitre',
    contracted: 'Cl',
    plural: 'Centilitres',
  },
  { id: 'LITRE', name: 'Litre', contracted: 'L' },
  {
    id: 'CAS',
    name: 'Cuillère à soupe',
    contracted: 'Cuillère à soupe',
    plural: 'Cuillères à soupe',
  },
  {
    id: 'CAC',
    name: 'Cuillère à café',
    contracted: 'Cuillère à café',
    plural: 'Cuillères à café',
  },
  { id: 'QS', name: 'Quantité suffisante', contracted: 'Quantité suffisante' },
  { id: 'PM', name: 'Pour memoire', contracted: 'Pour memoire' },
  { id: 'PINCEE', name: 'Pincée', contracted: 'Pincée', plural: 'Pincées' },
  { id: 'GOUTTE', name: 'Goutte', contracted: 'Goutte', plural: 'Gouttes' },
  { id: 'BOUQUET', name: 'Bouquet', contracted: 'Bouquet', plural: 'Bouquets' },
  { id: 'BOTTE', name: 'Botte', contracted: 'Botte', plural: 'Bottes' },
  { id: 'GOUSSE', name: 'Gousse', contracted: 'Gousse', plural: 'Gousses' },
  { id: 'TETE', name: 'Tête', contracted: 'Tête', plural: 'Têtes' },
  { id: 'PIECE', name: 'Pièce', contracted: 'Pièce', plural: 'Pièces' },
  { id: 'TIGE', name: 'Tige', contracted: 'Tige', plural: 'Tiges' },
  { id: 'BRANCHE', name: 'Branche', contracted: 'Branche', plural: 'Branches' },
  { id: 'TRANCHE', name: 'Tranche', contracted: 'Tranche', plural: 'Tranches' },
  { id: 'RACINE', name: 'Racine', contracted: 'Racine', plural: 'Racines' },
  {
    id: 'BARQUETTE',
    name: 'Barquette',
    contracted: 'Barquette',
    plural: 'Barquettes',
  },
];

export const ticket_status_enum = [
  { id: 'OPEN', name: 'Ouvert' },
  { id: 'IN_PROGRESS', name: 'En cours' },
  { id: 'CLOSE', name: 'Fermé' },
];

export const ticket_reply_owner_enum = [
  { id: 'CHEF', name: 'Chef' },
  { id: 'USER', name: 'Utilisateur' },
];
