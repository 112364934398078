import UserIcon from '@material-ui/icons/Group';

import UserEdit from './UserEdit';
import UserList from './UserList';

const resource = {
  list: UserList,
  edit: UserEdit,
  icon: UserIcon,
};

export default resource;
