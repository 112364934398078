import React from 'react';
import { Box } from '@material-ui/core';
import { ImageInput, ImageField } from 'react-admin';

const RecipeFormMedia = (props) => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <ImageInput
            source="image"
            label="Image de la recette"
            accept="image/*"
            placeholder="Déposez votre fichier ou cliquez pour le sélectionner. Le fichier doit être au format JPG ou PNG"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </Box>
      </Box>
    </Box>
  );
};

export default RecipeFormMedia;
