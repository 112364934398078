import React, { ReactElement } from 'react';
import { Datagrid, DateField, List, ListProps, TextField } from 'react-admin';

import UserLinkField from './UserLinkField';
import SubscriptionField from './SubscriptionField';
import SubscriptionFieldDateEnded from './SubscriptionFieldDateEnded';
import rowStyle from './rowStyle';

const UserList = (props: ListProps): ReactElement => {
  return (
    <List
      {...props}
      filters={undefined}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={25}
    >
      <Datagrid rowStyle={rowStyle} optimized rowClick="edit">
        <UserLinkField label="Utilisateur" />
        <TextField source="email" />
        <SubscriptionField label="Abonnement" />
        <SubscriptionFieldDateEnded label="Fin" />
        <TextField
          label="Favoris"
          source="user_favoris_aggregate.aggregate.count"
        />
        <DateField label="Créé le" source="created_at" />
      </Datagrid>
    </List>
  );
};

export default UserList;
