import * as React from 'react';
import { Edit, EditProps, FieldProps } from 'react-admin';

import { UserListFragment } from '../generated/graphql';
import FullNameField from './FullNameField';
import UserFormAccount from './UserFormAccount';

const UserTitle = ({ record }: FieldProps<UserListFragment>) =>
  record ? <FullNameField record={record} size="32" /> : null;

const UserEdit = ({ classes, ...props }: EditProps) => (
  <Edit component="div" title={<UserTitle />} {...props}>
    <UserFormAccount />
  </Edit>
);

export default UserEdit;
