import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { Link } from 'react-router-dom';
import { linkToRecord, useListContext } from 'react-admin';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  inline: {
    display: 'inline',
  },
});

const ChefListMobile = () => {
  const classes = useStyles();
  const { basePath, data, ids, loaded, total } = useListContext<any>();

  return loaded || Number(total) > 0 ? (
    <List className={classes.root}>
      {(ids as Exclude<typeof ids, undefined>).map((id) => {
        const item = (data as Exclude<typeof data, undefined>)[id];
        if (!item) return null;

        return (
          <Link
            to={linkToRecord(basePath, id)}
            className={classes.link}
            key={id}
          >
            <ListItem button>
              <ListItemText
                primary={`${item.firstname} ${item.lastname}`}
                secondary={item.description}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </Link>
        );
      })}
    </List>
  ) : null;
};

export default ChefListMobile;
