import React from 'react';
import { FieldProps, DateField } from 'react-admin';
import { UserListFragment } from '../generated/graphql';

const SubscriptionFieldDateEnded = (props: FieldProps<UserListFragment>) => {
  const { record } = props;
  if (!record) {
    return <></>;
  }
  if (record.user_subscription && record.user_subscription.ended_at) {
    return <DateField source="user_subscription.ended_at" />;
  }
  if (record.user_subscription_code && record.user_subscription_code.ended_at) {
    return <DateField source="user_subscription_code.ended_at" />;
  }
  return <></>;
};

export default SubscriptionFieldDateEnded;
