import gql from 'graphql-tag';

export const GET_LIST_RECIPE_STEP_PRODUCT = gql`
  fragment recipeStepProductList on recipe_step_product {
    id
    label
    product_id
    description
    quantity
    product_quantity_type {
      description
      value
    }
    product {
      id
      name
      name_plural
      type
      round_quantity
      in_grocery
      weight
      sugars
      carbohydrate
      fat
      protein
      energie
    }
  }
`;
