import * as React from 'react';
import { useDelete, useNotify, useRefresh } from 'react-admin';
import { Flex } from 'rebass';

const SeasonDelete = ({ id, children }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteOne, { error }] = useDelete(
    'product_season_month',
    id,
    {},
    {
      onSuccess: () => {
        notify('Saison supprimée');
        refresh();
      },
      onFailure: ({ error }) => {
        notify(error.message, 'error');
      },
    }
  );
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      p={4}
      width={[1 / 2, 1 / 4]}
      onClick={deleteOne}
      sx={{
        border: '1px solid black',
        cursor: 'pointer',
        backgroundColor: '#ff9800',
        ':hover': {
          backgroundColor: 'white',
        },
      }}
    >
      {children}
    </Flex>
  );
};

export default SeasonDelete;
