import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useUpdate,
  useNotify,
  useRefresh,
  FormWithRedirect,
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const RecipeStepQuickEditButton = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate('recipe_step', data.id);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    update(
      { payload: { data: { ...values } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  const { name } = data;

  return (
    <>
      <Button onClick={handleClick} label="Éditer">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Modifier l'étape : {name}</DialogTitle>
        <FormWithRedirect
          resource="recipe_step"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  label="Nom de l'étape"
                  source="name"
                  validate={required()}
                  initialValue={name}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default RecipeStepQuickEditButton;
