import gql from 'graphql-tag';

export const GET_LIST_PRODUCT = gql`
  fragment productList on product {
    id
    name
    name_plural
    type
    round_quantity
    in_grocery
    weight
    sugars
    carbohydrate
    fat
    protein
    energie
    product_season_months {
      id
      product_id
      season_month
    }
    recipe_step_products_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ONE_PRODUCT = gql`
  fragment productOne on product {
    id
    name
    name_plural
    type
    round_quantity
    in_grocery
    weight
    sugars
    carbohydrate
    fat
    protein
    energie
  }
`;
