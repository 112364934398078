import React from 'react';
import { TextInput, SelectInput, BooleanInput } from 'react-admin';
import { Box, Flex, Text } from 'rebass';

import { product_type_enum } from '../enums';

const ProductForm = (props) => {
  const { selected } = props;
  return (
    <Flex width={1}>
      <Box width={2 / 3}>
        <Text p={2}>Informations</Text>
        <Flex>
          <Box p={2} width={1 / 2}>
            <TextInput
              fullWidth
              label="Nom du produit"
              source="name"
              initialValue={selected && selected.name}
              validation={{ required: true }}
            />
          </Box>
          <Box p={2} width={1 / 2}>
            <TextInput
              fullWidth
              label="Nom du produit au pluriel"
              source="name_plural"
              initialValue={selected && selected.name}
              validation={{ required: true }}
            />
          </Box>
        </Flex>
        <Flex>
          <Box p={2} width={1 / 2}>
            <SelectInput
              fullWidth
              label="Type"
              source="type"
              choices={product_type_enum}
              validation={{ required: true }}
            />
          </Box>
          <Box p={2} width={1 / 2}>
            <TextInput
              fullWidth
              label="Poid moyen (g)"
              source="weight"
              validation={{ required: true }}
            />
          </Box>
        </Flex>
        <Text p={2}>Affichage</Text>
        <Flex>
          <Box p={2} width={1 / 2}>
            <BooleanInput
              fullWidth
              source="in_grocery"
              defaultValue={true}
              label="Afficher dans la liste de courses"
            />
          </Box>
          <Box p={2} width={1 / 2}>
            <BooleanInput
              fullWidth
              source="round_quantity"
              label="Arrondir la quantité"
            />
          </Box>
        </Flex>
      </Box>
      <Box width={1 / 3}>
        <Text p={2}>Nutri Score</Text>
        <Box p={2}>
          <TextInput
            fullWidth
            label="Calories"
            source="energie"
            initialValue={
              selected?.composition?.filter(
                (composition) => composition.constCode === '328'
              )[0]?.teneur
            }
          />
        </Box>
        <Box p={2}>
          <TextInput
            fullWidth
            label="Sucres"
            source="sugars"
            initialValue={
              selected?.composition?.filter(
                (composition) => composition.constCode === '32000'
              )[0]?.teneur
            }
          />
        </Box>
        <Box p={2}>
          <TextInput
            fullWidth
            label="Glucides"
            source="carbohydrate"
            initialValue={
              selected?.composition?.filter(
                (composition) => composition.constCode === '31000'
              )[0]?.teneur
            }
          />
        </Box>
        <Box p={2}>
          <TextInput
            fullWidth
            label="Lipides"
            source="fat"
            initialValue={
              selected?.composition?.filter(
                (composition) => composition.constCode === '40000'
              )[0]?.teneur
            }
          />
        </Box>
        <Box p={2}>
          <TextInput
            fullWidth
            label="Protéines"
            source="protein"
            initialValue={
              selected?.composition?.filter(
                (composition) => composition.constCode === '25000'
              )[0]?.teneur
            }
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default ProductForm;
