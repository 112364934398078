import React from 'react';
import {
  Identifier,
  Datagrid,
  DateField,
  DatagridProps,
  ReferenceField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import UserLinkField from '../user/UserLinkField';

import rowStyle from './rowStyle';

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'transparent',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
  },
  rowCell: {
    padding: '6px 8px 6px 8px',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const MessageField = ({ record = {} }: any) => {
  return (
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '500px',
        whiteSpace: 'nowrap',
      }}
    >
      {record.message}
    </div>
  );
};

export interface TicketListDesktopProps extends DatagridProps {
  selectedRow?: Identifier;
}

const TicketListDesktop = ({
  selectedRow,
  ...props
}: TicketListDesktopProps) => {
  const classes = useListStyles();
  return (
    <Datagrid
      rowClick="edit"
      // @ts-ignore
      rowStyle={rowStyle(selectedRow)}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <ReferenceField label="Utilisateur" source="user.id" reference="user">
        <UserLinkField />
      </ReferenceField>
      <MessageField source="message" />
      <DateField label="Créé le" source="created_at" showTime={true} />
    </Datagrid>
  );
};

export default TicketListDesktop;
