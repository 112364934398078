"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const renderer_1 = require("@react-pdf/renderer");
const borderColor = '#e0f3fa';
const styles = renderer_1.StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#e0f3fa',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '45%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingTop: 5,
        paddingLeft: 8,
    },
    tva: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingTop: 5,
        textAlign: 'right',
        paddingRight: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingTop: 5,
        textAlign: 'right',
        paddingRight: 8,
    },
    price: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingTop: 5,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingTop: 5,
        paddingRight: 8,
    },
});
const InvoiceTableRow = ({ items }) => {
    const rows = items.map((item) => (react_1.default.createElement(renderer_1.View, { style: styles.row, key: item.sno.toString() },
        react_1.default.createElement(renderer_1.Text, { style: styles.description }, item.desc),
        react_1.default.createElement(renderer_1.Text, { style: styles.tva }, item.tva),
        react_1.default.createElement(renderer_1.Text, { style: styles.qty }, item.qty),
        react_1.default.createElement(renderer_1.Text, { style: styles.price }, Number.parseFloat(item.priceUnit).toFixed(2)),
        react_1.default.createElement(renderer_1.Text, { style: styles.price }, Number.parseFloat(item.priceHT).toFixed(2)),
        react_1.default.createElement(renderer_1.Text, { style: styles.amount }, Number.parseFloat(item.priceTTC).toFixed(2)))));
    return react_1.default.createElement(react_1.Fragment, null, rows);
};
exports.default = InvoiceTableRow;
