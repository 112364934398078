import React, { useState } from 'react';
import {
  Button,
  useCreate,
  useNotify,
  useRefresh,
  FormWithRedirect,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import RecipeStepProductForm from './RecipeStepProductForm';

const RecipeStepProductQuickCreateButton = ({ data }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('recipe_step_product');
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: { ...values, recipe_step_id: data.id } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Ajouter un ingédient">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Ajouter un ingrédient à l'étape {data.name}</DialogTitle>
        <FormWithRedirect
          resource="recipe_step"
          save={handleSubmit}
          render={form => (
            <RecipeStepProductForm
              {...form}
              loading={loading}
              handleCloseClick={handleCloseClick}
            />
          )}
        />
      </Dialog>
    </>
  );
};

export default RecipeStepProductQuickCreateButton;
