import * as React from 'react';
import { FC } from 'react';
import {
  Identifier,
  Datagrid,
  ReferenceField,
  TextField,
  DatagridProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { recipe_type_enum } from '../enums';

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'transparent',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
  },
  rowCell: {
    padding: '6px 8px 6px 8px',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const RecipeTypeField = ({ record = {} }: any) => (
  <span>
    {recipe_type_enum.filter((type) => type.id === record.type)[0].name}
  </span>
);

export interface ReviewListDesktopProps extends DatagridProps {
  selectedRow?: Identifier;
}

export const CategoryListDesktop = ({
  selectedRow,
  ...props
}: ReviewListDesktopProps) => {
  const classes = useListStyles();
  return (
    <Datagrid
      rowClick="edit"
      // @ts-ignore
      // rowStyle={rowStyle(selectedRow)}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <TextField label="Nom" source="name" />
      <RecipeTypeField source="type" />
      <ReferenceField
        label="Catégorie parente"
        source="parent_id"
        reference="category"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  );
};
