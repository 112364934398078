import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { differenceInDays } from 'date-fns';

const rowStyle = (record: any) => {
  let style = {};
  if (!record) {
    return style;
  }
  if (
    record.user_subscription_code &&
    record.user_subscription_code.ended_at &&
    differenceInDays(
      new Date(record.user_subscription_code.ended_at),
      new Date()
    ) < 7
  ) {
    return {
      ...style,
      backgroundColor: orange[50],
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  if (record.is_active)
    return {
      ...style,
      backgroundColor: green[50],
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (!record.is_active)
    return {
      ...style,
      backgroundColor: red[50],
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return style;
};

export default rowStyle;
