import TicketIcon from '@material-ui/icons/Forum';

import TicketList from './TicketList';

const resource = {
  list: TicketList,
  icon: TicketIcon,
};

export default resource;
