import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
  region: 'eu-west-3',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

const uploadToS3 = (file, bucketName, key) => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: file,
    ACL: 'public-read', // adjust the ACL as per your requirement
  };

  return s3.upload(params).promise();
};

const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (type === 'UPDATE') {
    const bucketName = 'lesfoodcuisine-s3';
    let file, key;

    if (
      resource === 'recipe' &&
      params.data.image &&
      params.data.image.rawFile
    ) {
      file = params.data.image.rawFile;
      key = `recipes/${params.data.id}`; // Adjust the key as per your requirement
    } else if (
      resource === 'chef' &&
      params.data.avatar &&
      params.data.avatar.rawFile
    ) {
      file = params.data.avatar.rawFile;
      key = `chefs/${params.data.id}`; // Adjust the key as per your requirement
    }

    if (file) {
      return uploadToS3(file, bucketName, key)
        .then((data) => {
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              [resource === 'recipe' ? 'image' : 'avatar']: {
                src: data.Location,
              },
            },
          });
        })
        .catch((err) => console.error(err));
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
