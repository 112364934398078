import UserInvoiceIcon from '@material-ui/icons/Receipt';

import UserInvoiceList from './UserInvoiceList';
import UserInvoiceEdit from './UserInvoiceEdit';

const resource = {
  list: UserInvoiceList,
  edit: UserInvoiceEdit,
  icon: UserInvoiceIcon,
};

export default resource;
