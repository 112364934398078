import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  ReferenceInput,
  SelectInput,
  SaveButton,
  Toolbar,
  translate,
} from 'react-admin';
// import Button from '@material-ui/core/Button';

import { recipe_type_enum } from '../enums';

const CategoryEditToolbar = translate(
  ({ onCancel, translate, ...props }: any) => (
    <Toolbar {...props}>
      <SaveButton label="Enregistrer" />
      {/* <Button onClick={onCancel}>Annuler</Button> */}
    </Toolbar>
  )
);

export const CategoryEdit = ({ onCancel, ...props }: any) => (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={/*<CategoryEditToolbar onCancel={onCancel} />*/ <></>}>
      <SelectInput
        label="Type"
        source="type"
        choices={recipe_type_enum.filter((type) => type.id !== 'LIVE')}
      />
      <ReferenceInput source="parent_id" reference="category">
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput label="Nom" source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
