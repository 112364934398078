import React from 'react';
import { Edit, EditProps, FieldProps } from 'react-admin';

import { SubscriptionCodeListFragment } from '../generated/graphql';
import SubscriptionCodeForm from './SubscriptionCodeForm';

const SubscriptionCodeTitle = ({
  record,
}: FieldProps<SubscriptionCodeListFragment>) => (
  <span>Code d‘abonnement #{record.id}</span>
);

const SubscriptionCodeEdit = ({ classes, ...props }: EditProps) => (
  <Edit title={<SubscriptionCodeTitle />} {...props}>
    <SubscriptionCodeForm isEdit={true} />
  </Edit>
);

export default SubscriptionCodeEdit;
