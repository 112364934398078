import React from 'react';
import { Admin, Resource, defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

import addUploadFeature from '../addUploadFeature';
import { Layout } from './layout';
import user from '../user';
import userSubscription from '../userSubscription';
import userInvoice from '../userInvoice';
import subscriptionCode from '../subscriptionCode';
import category from '../category';
import product from '../product';
import recipe from '../recipe';
import ticket from '../ticket';
import chef from '../chef';
import theme from '../theme';

const customTheme = merge({}, defaultTheme, theme);

const App = (props: any) => {
  return (
    <Admin
      dataProvider={addUploadFeature(props.dataProvider)}
      // dashboard={DashBoard}
      layout={Layout}
      theme={customTheme}
    >
      <Resource name="user" options={{ label: 'Utilisateurs' }} {...user} />
      <Resource
        name="user_subscription"
        options={{ label: 'Abonnements' }}
        {...userSubscription}
      />
      <Resource
        name="user_invoice"
        options={{ label: 'Factures' }}
        {...userInvoice}
      />
      <Resource
        name="subscription_code"
        options={{ label: 'Codes d‘abonnement' }}
        {...subscriptionCode}
      />
      <Resource
        name="category"
        options={{ label: 'Categories' }}
        {...category}
      />
      <Resource name="product" options={{ label: 'Produits' }} {...product} />
      <Resource name="product_season_month" />
      <Resource name="recipe" options={{ label: 'Recettes' }} {...recipe} />
      <Resource name="recipe_season_month" />
      <Resource name="ticket" options={{ label: 'Euh...Chef ?' }} {...ticket} />
      <Resource name="ticket_reply" />
      <Resource name="chef" options={{ label: 'Les chefs' }} {...chef} />
    </Admin>
  );
};

export default App;
