import React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';
import AvatarField from './AvatarField';
import { UserListFragment } from '../generated/graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

interface Props extends FieldProps<UserListFragment> {
  size?: string;
}

const FullNameField = ({ record, size }: Props) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField className={classes.avatar} record={record} size={size} />
      {record.lastname} {record.firstname}
    </div>
  ) : null;
};

FullNameField.defaultProps = {
  source: 'lastname',
  label: 'resources.customers.fields.name',
};

export default memo<Props>(FullNameField);
