import * as React from 'react';
import { useCreate, useNotify, useRefresh } from 'react-admin';
import { Flex } from 'rebass';

const SeasonCreate = ({ recipeId, seasonMonth, children }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { error }] = useCreate(
    'recipe_season_month',
    {
      recipe_id: recipeId,
      season_month: seasonMonth,
    },
    {
      onSuccess: ({ data }) => {
        notify('Saison ajoutée');
        refresh();
      },
      onFailure: ({ error }) => {
        notify(error.message, 'error');
      },
    }
  );
  if (error) {
    return <Flex>ERROR</Flex>;
  }
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      p={4}
      width={[1 / 2, 1 / 4]}
      onClick={create}
      sx={{
        border: '1px solid black',
        cursor: 'pointer',
        backgroundColor: 'white',
        ':hover': {
          backgroundColor: '#ff9800',
        },
      }}
    >
      {children}
    </Flex>
  );
};

export default SeasonCreate;
