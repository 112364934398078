import gql from 'graphql-tag';

export const GET_LIST_SUBSCRIPTION_CODE = gql`
  fragment subscriptionCodeList on subscription_code {
    id
    code
    created_at
    started_at
    user_count
    time
    created_at
    type
    label
  }
`;
