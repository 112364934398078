import React from 'react';
import { Link, FieldProps } from 'react-admin';

import { UserListFragment } from '../generated/graphql';
import FullNameField from './FullNameField';

const UserLinkField = (props: FieldProps<UserListFragment>) => {
  return props.record ? (
    <Link to={`/user/${props.record.id}`}>
      <FullNameField {...props} />
    </Link>
  ) : null;
};

UserLinkField.defaultProps = {
  source: 'id',
  addLabel: true,
};

export default UserLinkField;
