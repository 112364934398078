import React from 'react';
import { AppBar, AppBarProps, Layout, LayoutProps } from 'react-admin';
import Logout from '../Logout';

const CustomAppBar = (props: AppBarProps) => {
  return <AppBar {...props} userMenu={<Logout button />} />;
};

export const CustomLayout = (props: LayoutProps) => {
  return <Layout {...props} appBar={CustomAppBar} />;
};
