import React from 'react';
import { Edit, EditProps, FieldProps } from 'react-admin';
import { Box } from '@material-ui/core';

import { UserSubscriptionListFragment } from '../generated/graphql';
import UserSubscriptionForm from './UserSubscriptionForm';
import UserLinkField from '../user/UserLinkField';

const UserSubscriptionTitle = ({
  record,
}: FieldProps<UserSubscriptionListFragment>) => {
  return record?.user ? (
    <Box display={{ sm: 'flex' }}>
      <UserLinkField record={record?.user} />
      &nbsp;-&nbsp;Abonnement
    </Box>
  ) : null;
};

const UserSubscriptionEdit = ({ classes, ...props }: EditProps) => (
  <Edit component="div" title={<UserSubscriptionTitle />} {...props}>
    <UserSubscriptionForm />
  </Edit>
);

export default UserSubscriptionEdit;
