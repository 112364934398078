import React from 'react';
import { Box } from '@material-ui/core';

import 'tinymce/skins/ui/oxide/skin.min.css';

import TinyMCEInput from '@palustris/ra-tinymce-input';

const RecipeFormDetails = (props) => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <TinyMCEInput
            apiKey="aldtfh75sucvggaqn8armzdhv2sfr5xll2bkjra7yoij0t8u"
            language="fr_FR"
            label="description"
            source="description"
            plugins={['code']}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RecipeFormDetails;
