import React, { useCallback } from 'react';
import { Filter, List, SearchInput, TextInput } from 'react-admin';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Drawer, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TicketListMobile from './TicketListMobile';
import TicketListDesktop from './TicketListDesktop';
import TicketEdit from './TicketEdit';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const TicketFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
    <TextInput source="email" />
  </Filter>
);

const TicketList = (props: any) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/ticket');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/ticket/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <>
              <List
                {...props}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                // bulkActionButtons={<TicketsBulkActionButtons />}
                filters={<TicketFilter />}
                perPage={25}
                sort={{ field: 'created_at', order: 'DESC' }}
              >
                {isXSmall ? (
                  <TicketListMobile />
                ) : (
                  <TicketListDesktop
                    selectedRow={
                      isMatch
                        ? parseInt((match as any).params.id, 10)
                        : undefined
                    }
                  />
                )}
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <TicketEdit
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  );
};

export default TicketList;
