import * as React from 'react';
import { useQuery, Error } from 'react-admin';
import { Box, Flex, Text } from 'rebass';

import RecipeStepProductQuickEditButton from './RecipeStepProductQuickEditButton';
import RecipeStepProductQuickDeleteButton from './RecipeStepProductQuickDeleteButton';

const RecipeStepProductList = ({ recipestepid }) => {
  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'recipe_step_product',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'created_at', order: 'ASC' },
      filter: { recipe_step_id: recipestepid },
    },
  });

  if (loading) return <></>;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <Flex>
      <Box>
        {data.map((product) => (
          <Flex flexDirection="column">
            <Flex sx={{ alignItems: 'flex-start' }}>
              <Flex alignItems="center" mb={1}>
                <Text fontSize={2} key={product.label}>
                  {product.label}
                </Text>
                <RecipeStepProductQuickEditButton data={product} />
                <RecipeStepProductQuickDeleteButton data={product} />
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Box>
    </Flex>
  );
};

export default RecipeStepProductList;
