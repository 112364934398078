import * as React from 'react';
import {
  useEditController,
  EditContextProvider,
  SimpleForm,
  EditProps,
  TextInput,
  NumberInput,
  ImageInput,
  ImageField,
  BooleanInput,
  required,
  Toolbar,
  SaveButton,
  Button,
  translate,
} from 'react-admin';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { ChefListFragment } from '../generated/graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}));

interface Props extends EditProps {
  onCancel: () => void;
}

const ChefEditToolbar = ({ onCancel, ...props }: any) => (
  <Toolbar {...props}>
    <SaveButton label="Enregistrer" />
    <Button onClick={onCancel} label="Annuler" />
  </Toolbar>
);

const ChefEdit = ({ onCancel, ...props }: Props) => {
  const classes = useStyles();
  const controllerProps = useEditController<ChefListFragment>(props);

  if (!controllerProps.record) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Modifier un chef</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={<ChefEditToolbar onCancel={onCancel} />}
        >
          <TextInput
            fullWidth
            label="Prénom"
            source="firstname"
            validate={required()}
          />
          <TextInput
            fullWidth
            label="Nom"
            source="lastname"
            validate={required()}
          />
          <TextInput
            fullWidth
            label="Description"
            source="description"
            validate={required()}
          />
          <NumberInput
            fullWidth
            label="Ordre"
            source="order"
            validate={required()}
          />
          <BooleanInput
            label="Actif"
            source="is_active"
            defaultValue={true}
            validate={required()}
          />
          <ImageInput
            source="avatar"
            label="Avatar"
            accept="image/*"
            placeholder="Upoader l'avatar du chef."
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default ChefEdit;
