import RecipeIcon from '@material-ui/icons/LibraryBooks';

import RecipeCreate from './RecipeCreate';
import RecipeEdit from './RecipeEdit';
import RecipeList from './RecipeList';

const resource = {
  list: RecipeList,
  create: RecipeCreate,
  edit: RecipeEdit,
  icon: RecipeIcon,
};

export default resource;
