import * as React from 'react';
import { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import {
  BulkDeleteButton,
  List,
  ListProps,
  BulkActionProps,
} from 'react-admin';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import CategoryCreate from './CategoryCreate';
import { CategoryEdit } from './CategoryEdit';
import { CategoryListDesktop } from './CategoryListDesktop';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 48,
  },
}));

const CategoryList = (props: ListProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/category');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/category/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Fragment>
              <List
                {...props}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                // actions={<CategoryListActions />}
                perPage={25}
              >
                <CategoryListDesktop
                  selectedRow={
                    isMatch ? parseInt((match as any).params.id, 10) : undefined
                  }
                />
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (
                  <CategoryEdit
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

// import CategoryCreate from './CategoryCreate';
// import CategoryEdit from './CategoryEdit';

// const styles = {
//   drawerContent: {
//     width: 300,
//   },
// };

// const useQuickFilterStyles = makeStyles((theme: any) => ({
//   chip: {
//     marginBottom: theme.spacing(1),
//   },
// }));

// const QuickFilter = ({ label }: any) => {
//   const classes = useQuickFilterStyles();
//   return <Chip className={classes.chip} label={label} />;
// };

// const CategoryFilter = (props: any) => (
//   <Filter {...props}>
//     <SearchInput placeholder="Rechercher" source="q" alwaysOn />
//     <QuickFilter source="type" label="Recette" defaultValue="RECIPE" />
//     <QuickFilter source="type" label="Tour de main" defaultValue="TECHNIQUE" />
//     <QuickFilter source="type" label="Fondamentaux" defaultValue="BASIC" />
//   </Filter>
// );

// const CategoryListActionToolbar = withStyles({
//   toolbar: {
//     alignItems: 'center',
//     display: 'flex',
//   },
// })(({ classes, children, ...props }: any) => (
//   <div className={classes.toolbar}>
//     {Children.map(children, (button) => cloneElement(button, props))}
//   </div>
// ));

// const CategoryListActions = (props: ListProps) => {
//   const classes = useStyles();
//   const isXSmall = useMediaQuery<Theme>((theme) =>
//     theme.breakpoints.down('xs')
//   );
//   const history = useHistory();

//   const handleClose = useCallback(() => {
//     history.push('/reviews');
//   }, [history]);

//   return (
//     <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
//       {filters &&
//         cloneElement(filters, {
//           resource,
//           showFilter,
//           displayedFilters,
//           filterValues,
//           context: 'button',
//         })}
//       <CreateButton label="Ajouter" basePath={basePath} />
//       <ExportButton
//         label="Exporter"
//         disabled={total === 0}
//         resource={resource}
//         sort={currentSort}
//         filterValues={filterValues}
//         maxResults={maxResults}
//       />
//       {/* Add your custom actions */}
//     </TopToolbar>
//   );
// };

// const RecipeTypeField = ({ record = {} }: any) => (
//   <span>
//     {recipe_type_enum.filter((type) => type.id === record.type)[0].name}
//   </span>
// );

// class CategoryList extends React.Component {
//   render() {
//     // const { push, classes, ...props } = this.props;
//     return (
//       <>
//         <List
//           {...this.props}
//           filters={<CategoryFilter />}
//           actions={<CategoryListActions />}
//           title="Les catégories de recette"
//         >
//           <Datagrid rowClick="edit">
//             <TextField label="Nom" source="name" />
//             <RecipeTypeField source="type" />
//             <ReferenceField
//               label="Catégorie parente"
//               source="parent_id"
//               reference="category"
//             >
//               <TextField source="name" />
//             </ReferenceField>
//             <CategoryListActionToolbar>
//               <EditButton label="Editer" />
//             </CategoryListActionToolbar>
//           </Datagrid>
//         </List>
//         <Route
//           path="/category/create"
//           render={() => (
//             <Drawer open onClose={this.handleClose} anchor="right">
//               <CategoryCreate onCancel={this.handleClose} {...props} />
//             </Drawer>
//           )}
//         />
//         <Route path="/category/:id">
//           {({ match }) => {
//             const isMatch =
//               match && match.params && match.params.id !== 'create';

//             return (
//               <Drawer open={isMatch} anchor="right" onClose={this.handleClose}>
//                 {isMatch ? (
//                   <CategoryEdit
//                     id={match.params.id}
//                     onCancel={this.handleClose}
//                     {...props}
//                   />
//                 ) : (
//                   <div className={classes.drawerContent} />
//                 )}
//               </Drawer>
//             );
//           }}
//         </Route>
//       </>
//     );
//   }

//   // handleClose = () => {
//   //   this.props.push('/category');
//   // };
// }

export default CategoryList;
