import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { Link } from 'react-router-dom';
import { linkToRecord, useListContext } from 'react-admin';
import { format } from 'date-fns';

import AvatarField from '../user/AvatarField';

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  listItemGreen: {
    backgroundColor: green[50],
    borderLeftColor: green[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  listItemOrange: {
    backgroundColor: orange[50],
    borderLeftColor: orange[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  listItemGreenRed: {
    backgroundColor: red[50],
    borderLeftColor: red[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  inline: {
    display: 'inline',
  },
});

const TicketListMobile = () => {
  const classes = useStyles();
  const { basePath, data, ids, loaded, total } = useListContext<any>();

  const listItemStyle = (status) => {
    if (status === 'OPEN') return classes.listItemGreen;
    if (status === 'IN_PROGRESS') return classes.listItemOrange;
    if (status === 'CLOSE') return classes.listItemGreenRed;
  };

  return loaded || Number(total) > 0 ? (
    <List className={classes.root}>
      {(ids as Exclude<typeof ids, undefined>).map((id) => {
        const item = (data as Exclude<typeof data, undefined>)[id];
        if (!item) return null;

        return (
          <Link
            to={linkToRecord(basePath, id)}
            className={classes.link}
            key={id}
          >
            <ListItem className={listItemStyle(item.status)} button>
              <ListItemAvatar>
                <AvatarField record={item.user} size="40" />
              </ListItemAvatar>
              <ListItemText
                primary={`${item.user.firstname} ${
                  item.user.lastname
                } - ${format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}`}
                secondary={item.message}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </Link>
        );
      })}
    </List>
  ) : null;
};

export default TicketListMobile;
