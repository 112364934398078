import React from 'react';
import { DateTimeInput, FormWithRedirect } from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const UserInvoiceForm = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    Information
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateTimeInput
                        disabled
                        fullWidth
                        label="Créé le"
                        source="created_at"
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}></Box>
                  </Box>
                  <Box mt="1em" />
                </Box>
              </Box>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

export default UserInvoiceForm;
