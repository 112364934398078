import React from 'react';
import {
  DeleteWithConfirmButton,
  useNotify,
  useRefresh,
  useRedirect,
  Edit,
  EditProps,
  FormTab,
  TabbedForm,
  Toolbar,
  SaveButton,
  TopToolbar,
  ListButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import RecipeForm from './RecipeForm';
import RecipeFormProduct from './FormProduct';
import RecipeFormDetails from './FormDetails';
import RecipeFormMedia from './FormMedia';
import RecipeFormSeason from './RecipeFormSeason';

const RecipeTitle = ({ record }: any) => {
  return <span>La recette - {record.name}</span>;
};

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const RecipeEditActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const RecipeEditToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton />
  </Toolbar>
));

const RecipeEdit = ({ classes, ...props }: EditProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = async ({ data }) => {
    await fetch(`${process.env.REACT_APP_API}/elasticsearch-update`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recipeId: data.id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((response) => {
        notify(`Modification de la recette "${data.name}"`);
        redirect('/recipe');
        refresh();
      });
  };

  return (
    <Edit
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}
      actions={<RecipeEditActions />}
      title={<RecipeTitle />}
    >
      <TabbedForm toolbar={<RecipeEditToolbar />}>
        <FormTab label="Recette">
          <RecipeForm isEdit />
        </FormTab>
        <FormTab label="Ingrédients">
          <RecipeFormProduct {...props} />
        </FormTab>
        <FormTab label="Détails">
          <RecipeFormDetails />
        </FormTab>
        <FormTab label="Médias">
          <RecipeFormMedia />
        </FormTab>
        <FormTab label="Saisons">
          <RecipeFormSeason {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default RecipeEdit;
