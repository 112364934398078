import React from 'react';
import { Edit, EditProps, FieldProps } from 'react-admin';
import { Box } from '@material-ui/core';

import { UserInvoiceListFragment } from '../generated/graphql';
import { InvoiceNumberField } from './InvoiceNumberField';
import UserInvoiceForm from './UserInvoiceForm';
import UserLinkField from '../user/UserLinkField';

const UserInvoiceTitle = ({ record }: FieldProps<UserInvoiceListFragment>) => {
  return record?.user_subscription?.user ? (
    <Box display={{ sm: 'flex' }}>
      <UserLinkField record={record?.user_subscription?.user} />
      &nbsp;-&nbsp;
      <InvoiceNumberField record={record} />
    </Box>
  ) : null;
};

const UserInvoiceEdit = ({ classes, ...props }: EditProps) => (
  <Edit component="div" title={<UserInvoiceTitle />} {...props}>
    <UserInvoiceForm />
  </Edit>
);

export default UserInvoiceEdit;
