import gql from 'graphql-tag';

export const GET_LIST_RECIPE = gql`
  fragment recipeList on recipe {
    id
    name
    type
    video
    video_live_event
    preparation_time
    total_time
    number
    category
    number_type
    is_publish
    created_at
    updated_at
    image
    utensil
    level
    description
    live_date
    recipe_steps {
      id
      name
      recipe
      recipe_step_products {
        id
        label
        product_id
        description
        quantity
        product_quantity_type {
          description
          value
        }
        product {
          id
          name
          name_plural
          type
          round_quantity
          in_grocery
          weight
          sugars
          carbohydrate
          fat
          protein
          energie
        }
      }
    }
    user_evaluations_aggregate {
      aggregate {
        count
        avg {
          evaluation
        }
      }
    }
  }
`;

export const GET_ONE_RECIPE = gql`
  fragment recipeOne on recipe {
    id
    name
    type
    video
    video_live_event
    preparation_time
    total_time
    number
    category
    number_type
    is_publish
    created_at
    updated_at
    image
    utensil
    level
    description
    live_date
  }
`;
