import React, { cloneElement } from 'react';
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';

import { product_type_enum } from '../enums';

const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
    <TextInput source="email" />
  </Filter>
);

const ProductListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton label="Ajouter" basePath={basePath} />
      <ExportButton
        label="Exporter"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

const ProductTypeField = ({ record = {} }: any) => (
  <span>
    {product_type_enum.filter((type) => type.id === record.type)[0]?.name}
  </span>
);

const ProductList = (props) => (
  <List
    title="Les ingrédients"
    {...props}
    filters={<ProductFilter />}
    actions={<ProductListActions />}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ProductTypeField source="type" />
      <TextField
        label="Nbre de recette"
        source="recipe_step_products_aggregate.aggregate.count"
      />
    </Datagrid>
  </List>
);

export default ProductList;
