import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  TextField,
  TextInput,
  ReferenceField,
} from 'react-admin';

import UserLinkField from '../user/UserLinkField';

const UserSubscriptionFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
    <TextInput source="email" />
  </Filter>
);

const TypeField = ({ record = {} }: any) => (
  <span>{record.type === 'MONTHLY' ? 'Mensuel' : 'Annuel'}</span>
);

const UserSubscriptionList = (props: any) => (
  <List
    title="Abonnements"
    {...props}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<UserSubscriptionFilter />}
  >
    <Datagrid optimized rowClick="edit">
      <ReferenceField label="Utilisateur" source="user_id" reference="user">
        <UserLinkField />
      </ReferenceField>
      <TypeField source="type" />
      <TextField source="status" />
      <DateField label="Début le" source="started_at" />
      <DateField label="Créé le" source="created_at" />
    </Datagrid>
  </List>
);

export default UserSubscriptionList;
