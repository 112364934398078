import React from 'react';
import {
  TextInput,
  SelectInput,
  BooleanInput,
  DateInput,
  Toolbar,
  required,
  email,
  FormWithRedirect,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
}));

const SubscriptionField = ({ record = {} }: any) => {
  const classes = useStyles();

  if (record.user_subscription_code) {
    return (
      <span>
        Code :{' '}
        <Link
          className={classes.link}
          to={`/subscription_code/${record.user_subscription_code.subscription_code.id}`}
        >
          {record.user_subscription_code.subscription_code.label}
          {' - '}
          {record.user_subscription_code.subscription_code.time} mois
        </Link>
      </span>
    );
  }
  if (record.user_subscription) {
    return (
      <span>
        Abonnement :{' '}
        <Link
          className={classes.link}
          to={`/user_subscription/${record.user_subscription.id}`}
        >
          {record.user_subscription.type === 'MONTHLY' ? 'Mensuel' : 'Annuel'}
        </Link>
      </span>
    );
  }
  return <span></span>;
};

const UserFormAccount = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    Identité
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <SelectInput
                        fullWidth
                        label="Civilité"
                        source="gender"
                        choices={[
                          { id: 'FEMALE', name: 'Mme.' },
                          { id: 'MALE', name: 'M.' },
                        ]}
                      />
                    </Box>
                    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source="firstname"
                        label="Prénom"
                        resource="user"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source="lastname"
                        label="Nom"
                        resource="user"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        type="email"
                        source="email"
                        resource="user"
                        label="Email"
                        validate={[email(), required()]}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        type="phone"
                        source="phone"
                        resource="user"
                        label="Téléphone"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateInput
                        source="birthday"
                        resource="user"
                        label="Date d'anniversaire"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} />
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Adresse
                  </Typography>
                  <TextInput
                    source="address"
                    resource="user"
                    label="Adresse"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source="zip"
                        resource="user"
                        label="Code postal"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={2}>
                      <TextInput
                        source="city"
                        resource="user"
                        label="Ville"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>

                  <Box mt="1em" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: '1em' }}
                  mt={{ xs: '1em', lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Profil
                  </Typography>
                  <div>
                    <TextInput fullWidth resource="user" source="username" />
                  </div>
                  <div>
                    <TextInput
                      fullWidth
                      resource="user"
                      multiline
                      source="description"
                    />
                  </div>
                  <Typography variant="h6" gutterBottom>
                    Abonnement
                  </Typography>
                  <div>
                    <BooleanInput
                      label="Abonnement en cours"
                      source="is_active"
                      resource="user"
                    />
                  </div>
                  <div>
                    <SubscriptionField {...props} />
                  </div>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="user"
            />
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default UserFormAccount;
