import React from 'react';
import {
  DateTimeInput,
  DateInput,
  TextInput,
  FormWithRedirect,
  Toolbar,
} from 'react-admin';
import { Box, Card, CardContent, Typography, Chip } from '@material-ui/core';

const StatusField = ({ record = {} }: any) => {
  return record.status === 'OPEN' ? (
    <Chip label="En cours" color="default" />
  ) : (
    <Chip label="Annulé" color="primary" />
  );
};

const UserSubscriptionForm = ({ classes, ...props }: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    Information
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateTimeInput
                        disabled
                        fullWidth
                        label="Créé le"
                        source="created_at"
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateTimeInput
                        fullWidth
                        label="Terminé le"
                        source="ended_at"
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateTimeInput
                        fullWidth
                        disabled
                        label="Premier paiement le"
                        source="started_at"
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <DateInput
                        fullWidth
                        label="Prochain paiement"
                        source="next_billing"
                      />
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Carte bancaire
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        disabled
                        fullWidth
                        label="Numéro de carte"
                        source="pan"
                      />
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        disabled
                        fullWidth
                        label="Mois d'expiration"
                        source="expiry_month"
                      />
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        disabled
                        fullWidth
                        label="Année d'expiration"
                        source="expiry_year"
                      />
                    </Box>
                  </Box>

                  <Box mt="1em" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: '1em' }}
                  mt={{ xs: '1em', lg: 0 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Status
                  </Typography>
                  <div>
                    <StatusField source="status" {...props} />
                  </div>
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Factures
                  </Typography>
                  <div></div>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="user_subscription"
            />
          </form>
        </Card>
      )}
    />
  );
};

export default UserSubscriptionForm;
