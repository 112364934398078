import React from 'react';
import { Create, CreateProps } from 'react-admin';

import SubscriptionCodeForm from './SubscriptionCodeForm';

const SubscriptionCodeCreate = (props: CreateProps) => {
  return (
    <Create title="Code d'abonnement" {...props}>
      <SubscriptionCodeForm />
    </Create>
  );
};

export default SubscriptionCodeCreate;
