import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { FieldProps } from 'react-admin';

import { UserListFragment } from '../generated/graphql';
interface Props extends FieldProps<UserListFragment> {
  className?: string;
  size?: string;
}

const AvatarField = ({ record, size = '25', className = '' }: Props) =>
  record ? (
    <Avatar
      src={`${process.env.REACT_APP_AWS_S3}${record.avatar_preview}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;

export default AvatarField;
