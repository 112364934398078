import * as React from 'react';
import {
  useEditController,
  EditContextProvider,
  SimpleForm,
  EditProps,
} from 'react-admin';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// import ProductReferenceField from '../products/ProductReferenceField';
import UserReferenceField from '../user/UserReferenceField';
// import StarRatingField from './StarRatingField';
// import TicketEditToolbar from './TicketEditToolbar';
import { TicketListFragment } from '../generated/graphql';
import TicketForm from './TicketForm';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}));

interface Props extends EditProps {
  onCancel: () => void;
}

const TicketEdit = ({ onCancel, ...props }: Props) => {
  const classes = useStyles();
  const controllerProps = useEditController<TicketListFragment>(props);

  if (!controllerProps.record) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Euh...Chef ?</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={<></>}
        >
          <UserReferenceField formClassName={classes.inlineField} />
          <TicketForm />
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default TicketEdit;
