import gql from 'graphql-tag';

export const GET_LIST_TICKET = gql`
  fragment ticketList on ticket {
    id
    message
    status
    created_at
    user {
      id
      is_active
      created_at
      email
      password
      firstname
      lastname
      avatar
      avatar_preview
      avatar_color
      gender
      username
      description
      phone
      address
      zip
      city
      birth
    }
    ticket_replies(order_by: { created_at: asc }) {
      id
      message
      owner
      created_at
      is_read
    }
  }
`;
