import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  TextInput,
} from 'react-admin';

import { UserInvoiceDownload } from './UserInvoiceDownload';
import { InvoiceNumberField } from './InvoiceNumberField';
import { AmountField } from './AmountField';
import UserLinkField from '../user/UserLinkField';

const UserInvoiceFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Rechercher" source="q" alwaysOn />
    <TextInput source="email" />
  </Filter>
);

const UserInvoiceList = (props: any) => (
  <List
    title="Factures"
    {...props}
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<UserInvoiceFilter />}
  >
    <Datagrid rowClick="edit">
      <InvoiceNumberField label="Numéro de facture" />
      <ReferenceField
        label="Utilisateur"
        source="user_subscription_id"
        reference="user_subscription"
      >
        <ReferenceField label="Utilisateur" source="user_id" reference="user">
          <UserLinkField />
        </ReferenceField>
      </ReferenceField>
      <AmountField label="Montant" />
      <DateField label="Créé le" source="created_at" />
      <UserInvoiceDownload />
    </Datagrid>
  </List>
);

export default UserInvoiceList;
