import React from 'react';
import { Flex } from 'rebass';

import SeasonList from './SeasonList';

const RecipeFormSeason = ({ id, record }: any) => {
  return (
    <Flex width={1}>
      <SeasonList recipeId={id} {...record} />
    </Flex>
  );
};

export default RecipeFormSeason;
