import * as React from 'react';
import { ReferenceField, ReferenceFieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
  }
) => (
  <ReferenceField
    label="Utilisateur"
    source="user.id"
    reference="user"
    {...props}
  >
    <FullNameField />
  </ReferenceField>
);

CustomerReferenceField.defaultProps = {
  source: 'user.id',
  label: 'Utilisateur',
  addLabel: true,
};

export default CustomerReferenceField;
