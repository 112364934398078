import React, { useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import { useDebouncedCallback } from 'use-debounce';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const MyAutoComplete = ({ options, search, selected }) => (
  <Autocomplete
    options={options}
    getOptionLabel={(option: any) => option.name}
    style={{ width: '100%', height: '80px' }}
    onChange={(event, newValue) => {
      selected(newValue);
    }}
    onInputChange={(event, newInputValue) => {
      search(newInputValue);
    }}
    renderInput={(params) => <TextField {...params} label="Ingrédient" />}
  />
);

const ProductAutocomplete = ({ classes, onSelect, ...props }: any) => {
  const [choices, setChoices] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedCallback = useDebouncedCallback((value: any) => {
    setSearch(value);
  }, 1000);

  useEffect(() => {
    if (search.length > 0) {
      fetch('https://hasura.lesfoodcuisine.fr/v1/graphql', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-hasura-admin-secret': 'WjqRluDWPsspRZp9PytYXiLQqyk3dV8g',
        },
        body: JSON.stringify({
          query: `{ product(where: {name: {_ilike: "${search}%"}}) { id name name_plural } }`,
        }),
      })
        .then((res) => res.json())
        .then((res) =>
          setChoices(
            res.data.product.map((data) => ({
              id: data.id,
              name: data.name,
              name_plural: data.name_plural,
            }))
          )
        );
    }
  }, [search]);

  return (
    <MyAutoComplete
      options={choices}
      search={debouncedCallback}
      selected={onSelect}
    />
  );
};

export default ProductAutocomplete;
