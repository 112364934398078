import * as React from 'react';
import { useQuery, Loading, Error } from 'react-admin';
import { Box, Flex, Text } from 'rebass';

import RecipeStepQuickEditButton from './RecipeStepQuickEditButton';
import RecipeStepProductQuickCreateButton from './RecipeStepProductQuickCreateButton';
import RecipeStepProductList from './RecipeStepProductList';
import RecipeStepQuickCreateButton from './RecipeStepQuickCreateButton';
import RecipeStepQuickDeleteButton from './RecipeStepQuickDeleteButton';

const RecipeStepList = ({ recipeid }) => {
  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'recipe_step',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'order', order: 'ASC' },
      filter: { recipe: recipeid },
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <Box width={1}>
      <Flex>
        <Box p={2} width={1}>
          <RecipeStepQuickCreateButton
            recipeid={recipeid}
            order={data.length + 1}
          />
        </Box>
      </Flex>
      <Flex>
        <Box p={2} width={1}>
          <Flex width="100%">
            <Box width="100%">
              {data.map((step) => (
                <Flex width="100%" flexDirection="column" mb={3}>
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    sx={{ alignItems: 'flex-start' }}
                    bg="#eeeeee"
                    p={2}
                  >
                    <Box>
                      <Text fontSize={3} key={step.name}>
                        Étape : {step.name}
                      </Text>
                    </Box>
                    <Box>
                      <RecipeStepProductQuickCreateButton data={step} />
                      <RecipeStepQuickEditButton data={step} />
                      <RecipeStepQuickDeleteButton data={step} />
                    </Box>
                  </Flex>
                  <Box p={3}>
                    <RecipeStepProductList recipestepid={step.id} />
                  </Box>
                </Flex>
              ))}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default RecipeStepList;
